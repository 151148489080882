import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';

const NameValue = ({ name, children }) => (
  <li>
    <strong>{name}:</strong> {children}
  </li>
);

const EventCodaTemplate = ({
  title,
  price,
  ourTicketShop,
  location,
  organizer,
  over24h,
  primary,
  cancelled,
  cancelled_reason,
  start_dow,
  start_date,
  start_time,
  end_date,
  end_time,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section">
      <h2 className="title">{title}</h2>
      {cancelled && (
        <div className="notification is-danger">
          Tapahtuma peruttu{cancelled_reason && `: ${cancelled_reason}`}.
        </div>
      )}
      <ul className="box">
        <NameValue name="Aika">
          {start_dow}{' '}
          <span className="has-text-weight-semibold">{start_date} </span>
          {start_time} &mdash;{' '}
          {over24h ? (
            <span className="has-text-weight-semibold">{end_date} </span>
          ) : null}
          {end_time}
        </NameValue>
        <NameValue name="Järjestäjä">{organizer}</NameValue>
        <NameValue name="Sijainti">{location}</NameValue>
        {price ? (
          <NameValue name="Liput">
            {price} {ourTicketShop ? <>&bull;{' '}
              <a href="https://clubx.eventiolive.fi/">Lippukauppa &rarr;</a>
            </> : undefined}
          </NameValue>
        ) : null}
      </ul>
      <PageContent className="content" content={content} />
    </section>
  );
};

const EventCoda = ({ data }) => {
  const { html, frontmatter } = data.event;

  return (
    <Layout>
      <EventCodaTemplate
        contentComponent={HTMLContent}
        content={html}
        {...frontmatter}
      />
    </Layout>
  );
};

EventCoda.propTypes = {
  data: PropTypes.shape({
    event: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default EventCoda;

export const eventCodaQuery = graphql`
  query EventCoda($id: String!) {
    event(id: { eq: $id }) {
      html
      frontmatter {
        title
        price
        ourTicketShop
        organizer
        location
        over24h
        primary
        cancelled
        cancelled_reason
        start_dow: start_date(formatString: "dd", locale: "fi")
        start_date(formatString: "l", locale: "fi")
        start_time: start_date(formatString: "LT", locale: "fi")
        end_date(formatString: "l", locale: "fi")
        end_time: end_date(formatString: "LT", locale: "fi")
      }
    }
  }
`;
